import React, { useState } from 'react';
import styles from './DataList.module.css';
import Pagination from '../Pagination/Pagination';
import Cookies from 'js-cookie';

const DataList = (props) => {
    const color = ["#8de02c", "#f9ca3f", "#f00", "#1795ce", "#fa7325", "#fa25d6"];
    const [activePagination, setActivePagination] = useState(0);
    const [showAllRow, setShowAll] = useState(false);

    function prevPage(value) {
        if (activePagination !== 0)
            setActivePagination(value);
    }
    function currentPage(value) {
        setActivePagination(value);
    }
    function nextPage(value) {
        if (activePagination !== props.row.length - 1)
            setActivePagination(value);
    }
    function showAllRowFun(value) {
        setShowAll(value);
        console.log(value);
    }

    return (
        <div className={[styles.details, styles.showAll].join(" ")}>
            <div className={[styles.mainContainer, styles.showAll].join(" ")} style={{ paddingBottom: props.pagination ? "35px" : "10px" }}>
                <div className={styles.cardHeader}>
                    <h5>({props.tableName === "NewVHCPcrmdata" ? "VHCPData" : props.tableName})</h5>
                    <h3><span className={styles.mainHeading}>{props.heading.split(" ")[0]}</span> {props.heading.split(" ").slice(1,).join(" ")} {props.subHeading ? (<span className={styles.subHeading}>({props.subHeading.split(" ")[0]} <span className={styles.sSubHeading}>{props.subHeading.split(" ")[1]}</span> {props.subHeading.split(" ").slice(2,).join(" ")})</span>) : ""}</h3>
                    <div className={styles.btnList}>
                        {props.btnParams.map((item, pos) => (
                            item.btnFun ? (
                                <button key={pos} onClick={item.btnFun} className={styles.btn}>{item.btnValue}</button>
                            ) : item.directDownload ? (
                                <a key={pos} href={encodeURI("data:text/csv;charset=utf-8," + props.col.map(elem => elem.replace("#", "")) + "\n" + props.row.map(item => item.map(elem => elem.join(",")).join("\n")).join("\n"))} download={item.filename} className={styles.btn}>{item.btnValue}</a>
                            ) : (
                                <a key={pos} href={process.env.REACT_APP_API_URL + "/api/file/" + encodeURIComponent(item.queryStr) + `&${Cookies.get("toc_crm")}`} download={item.filename} className={styles.btn}>{item.btnValue}</a>
                            )
                        ))}
                    </div>
                </div>
                <div className={styles.parentTableClass}>
                    <table>
                        <thead>
                            <tr>
                                {props.col !== undefined ? (
                                    props.col.map((item, pos) => (
                                        <td key={pos}>{item.replace(/(\b|_)\w/g, c => c.toUpperCase()).replaceAll("_", "")}</td>
                                    ))
                                ) : (<td></td>)}
                            </tr>
                        </thead>
                        <tbody>
                            {props.row !== undefined && props.row.length !== 0 ? (
                                showAllRow ? (
                                    props.row.map((r, i) => (
                                        props.row[i].map((items, pos1) => (
                                            <tr key={pos1} className={props.hasTotal && pos1 === props.row[i].length - 1 ? styles.totalTr : ""}>
                                                {items.map((item, pos2) => (
                                                    <td key={pos2}>
                                                        {pos2 === 1 ? props.hasTotal ? (pos1 === props.row[i].length - 1 ? (item ? item : "None") : (
                                                            <span style={{ background: color[Math.floor(Math.random() * color.length)] }} className={[styles.status].join(" ")}>{item ? item : "None"}</span>
                                                        )) : (
                                                            <span style={{ background: color[Math.floor(Math.random() * color.length)] }} className={[styles.status].join(" ")}>{item ? item : "None"}</span>
                                                        ) : (item ? item : "None")}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ))
                                ) : (
                                    props.row[activePagination].map((items, pos1) => (
                                        <tr key={pos1} className={props.hasTotal && pos1 === props.row[activePagination].length - 1 ? styles.totalTr : ""}>
                                            {items.map((item, pos2) => (
                                                <td key={pos2}>
                                                    {pos2 === 1 ? props.hasTotal ? (pos1 === props.row[activePagination].length - 1 ? (item ? item : "None") : (
                                                        <span style={{ background: color[Math.floor(Math.random() * color.length)] }} className={[styles.status].join(" ")}>{item ? item : "None"}</span>
                                                    )) : (
                                                        <span style={{ background: color[Math.floor(Math.random() * color.length)] }} className={[styles.status].join(" ")}>{item ? item : "None"}</span>
                                                    ) : (item ? item : "None")}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                )
                            ) : (<tr><td></td></tr>)}
                        </tbody>
                    </table>
                </div>
                {props.pagination ? (<Pagination rowLength={props.row.length} prevPage={prevPage}
                    currentPage={currentPage} nextPage={nextPage} showAll={showAllRowFun} />) : ""}
            </div>
        </div >
    )
}

export default DataList;
