import React, { useState } from 'react';
import styles from './SignIn.module.css';
import Cookies from 'js-cookie';
import { getToken } from '../../http';
import Spinner2 from '../../components/shared/Spinner/Spinner2';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../store/authSlice';

const SignIn = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [signinPassword, setSigninPassword] = useState("");
    const [spinner, setSpinner] = useState(false);

    async function signIn(event) {
        event.preventDefault();
        setSpinner(true);
        let loginData = `grant_type=&username=${email}&password=${signinPassword}&scope=&client_id=&client_secret=`;
        try {
            const { data } = await getToken(loginData);
            if (data) {
                Cookies.set('toc_crm', data.access_token, { expires: 1 / 48 });
                setTimeout(() => {
                    dispatch(setAuth(true));
                }, 2000);
            }
        } catch (error) {
            console.log("Unable to login");
            setSpinner(false);
        }
        setTimeout(() => {
            setSpinner(false);
        }, 1000);
    }

    return (
        <div className={styles.container}>
            <div className={styles.formsContainer}>
                <div className={styles.signin}>
                    <form onSubmit={signIn} className={styles.signInForm}>
                        <h2 className={styles.title}>Sign in</h2>
                        <div className={styles.inputField}>
                            <i className="fas fa-user"></i>
                            <input type="email" placeholder="Email" spellCheck={false} required={true} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className={styles.inputField}>
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Password" spellCheck={false} required={true} minLength="8" onChange={(e) => setSigninPassword(e.target.value)} />
                        </div>
                        {spinner ? <Spinner2 /> :
                            <input type="submit" value="Login" className={[styles.btn, styles.solid].join(" ")} />}
                    </form>
                </div>
            </div>

            <div className={styles.panelsContainer}>
                <div className={[styles.panel, styles.leftPanel].join(" ")}>
                    <div className={styles.content}>
                        <a className={styles.brandContainer} href="https://blueplanetsolutions.com/">
                            <span className={[styles.icon].join(" ")}>
                                <img className={[styles.brand].join(" ")} src="/images/brand.png" alt="Brand logo" />
                            </span>
                            <span className={[styles.title, styles.brandTitleWrapper].join(" ")}>
                                <p>BLUE PLANET</p>
                                <p>SOLUTIONS INC.</p>
                            </span>
                        </a>
                        <h3>Welcome to <span>CRM</span> Portal</h3>
                        <p>
                            This website is designed to analyse the CRM data. Our main domain is <a href="https://blueplanetsolutions.com">Blue Planet Solutions</a>
                        </p>
                    </div>
                    <img src="/images/log.svg" className={styles.image} alt="" />
                </div>
            </div>
        </div>
    )
}

export default SignIn;
