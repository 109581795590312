import React from 'react';
import styles from './Chart.module.css';
import PolarChart from '../ChartTypes/PolarChart';
import LineChart from '../ChartTypes/LineChart';

const Chart = (props) => {
    const labels = props.dataset.map((item, pos) => {
        return (
            pos !== props.dataset.length - 1 ? item[0] : null
        )
    });
    const data = props.dataset.map((item, pos) => {
        return (
            pos !== props.dataset.length - 1 ? props.hasTotal ? item[item.length - 1] : item[1] : null
        )
    });
    return (
        <div className={styles.graphBox}>
            <div className={styles.box}>
                <PolarChart labels={labels} data={data} label={props.label} />
            </div>
            <div className={styles.box}>
                <LineChart labels={labels} data={data} label={props.label} />
            </div>
        </div>
    )
}

export default Chart;
