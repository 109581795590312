import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './StepRequiredOptions.module.css';
import Button from '../../Button/Button';
import { setFieldSelection } from '../../../../store/utils';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import axios from 'axios';
import Cookies from 'js-cookie';

const StepRequiredOptions = (props) => {
    const { displayField, selection, tableName } = useSelector((state) => state.utils);
    const [fieldLocal, setFieldLocal] = useState(displayField ? displayField : []);
    const [fieldList, setFieldList] = useState([]);
    const [selectionLocal, setSelectionLocal] = useState(selection ? selection : "");
    const [selectionList, setSelectionList] = useState([]);
    const dispatch = useDispatch();
    const [borderColor, setBorderColor] = useState("#95bbfa");
    const [warning, setWarning] = useState(false);

    // const options = [
    //     { label: "Grapes", value: "grapes" },
    //     { label: "Mango", value: "mango" },
    //     { label: "Strawberry", value: "strawberry", disabled: true },
    // ];
    const optionsForField = fieldList.map(item => {
        return (
            { label: item.replaceAll("_", " "), value: item }
        )
    });
    const optionsForSelection = selectionList.map(item => {
        return (
            { label: item.replaceAll("_", " "), value: item }
        )
    });

    function nextStep() {
        if (fieldLocal.length !== 0 && selectionLocal) {
            let updatedFields = fieldLocal.map(item => (item.value));
            let updatedSelections = selectionLocal.value;
            dispatch(setFieldSelection({ displayField: updatedFields, selection: updatedSelections }));
            props.onNext();
        } else {
            setBorderColor("red");
            setWarning(true);
        }
    }


    useEffect(() => {
        async function fetchData() {
            try {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${Cookies.get("toc_crm")}`,
                    },
                });
                const { data } = await api.post("/api/index/", { tableName });
                if (data.success) {
                    setFieldList(data.sws);
                    setSelectionList(data.menu);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [tableName]);

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                {warning ? (
                    <p className={styles.warning}>
                        Please choose require option from the drop down !!
                    </p>
                ) : ""}
                <div className={styles.cardWrapper}>
                    <div style={fieldLocal.length !== 0 ? { border: "2px solid #95bbfa" } : { border: `2px solid ${borderColor}` }} className={styles.card} onPointerEnter={() => { setBorderColor("#95bbfa"); setWarning(false); }}>
                        <label className={styles.cardName} htmlFor="fields">Display Fields: </label>
                        <MultiSelect
                            isLoading={optionsForField ? false : true}
                            options={optionsForField}
                            value={fieldLocal}
                            onChange={setFieldLocal}
                            labelledBy="Select"
                            className={styles.select}
                            shouldToggleOnHover={true}
                        />
                    </div>
                    <div style={selection !== null ? { border: "2px solid #95bbfa" } : { border: `2px solid ${borderColor}` }} className={styles.card} onPointerEnter={() => { setBorderColor("#95bbfa"); setWarning(false); }}>
                        <label className={styles.cardName} htmlFor="selection">Selection: </label>
                        <Select defaultValue={selectionLocal} options={optionsForSelection} className={styles.select} onChange={setSelectionLocal} />
                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={nextStep} text="Next" />
                </div>
            </div>
        </div >
    )
}

export default StepRequiredOptions;
