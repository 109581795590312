import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SubmitForm.module.css';
import StepDatasetType from '../Steps/StepDatasetType/StepDatasetType';
import StepChooseDate from '../Steps/StepChooseDate/StepChooseDate';
import StepRequiredOptions from '../Steps/StepRequiredOptions/StepRequiredOptions';

const steps = {
    1: StepDatasetType,
    2: StepRequiredOptions,
    3: StepChooseDate
}

const SubmitForm = () => {
    const [step, setStep] = useState(1);
    const { tableName, displayField, selection, fromDate, toDate } = useSelector((state) => state.utils);

    function onNext() {
        setStep(step + 1);
    }

    function handleClick(value) {
        switch (value) {
            case 1:
                if (tableName)
                    setStep(value);
                break;
            case 2:
                if (displayField && selection)
                    setStep(value);
                break;
            case 3:
                if (fromDate && toDate)
                    setStep(value);
                break;
            default:
                break;
        }
    }

    const Step = steps[step];

    const array = ["Choose Dataset type", "Select required options", "Choose date"];

    return (
        <div>
            <div className={styles.pageHeading}>
                <h2><span>CRM</span> PORTAL</h2>
            </div>
            <div className={styles.stepsHeadingWrapper}>
                {array.map((item, pos) => (
                    <div key={pos} className={styles.card} onClick={() => handleClick(pos + 1)}>
                        <div className={styles.numbers}><span>{pos + 1}</span></div>
                        <div className={styles.cardName}>{item}</div>
                        {step > pos + 1 || (fromDate !== null && toDate !== null) ? (<i className="fas fa-check-circle"></i>) : ""}
                    </div>
                ))}
            </div>
            {/* Steps  */}
            <Step onNext={onNext} />
        </div>
    )
}

export default SubmitForm;
