import React from 'react';
import { PolarArea } from 'react-chartjs-2';

const PolarChart = (props) => {
    const state = {
        labels: props.labels.slice(0, -1),
        datasets: [
            {
                label: props.label,
                backgroundColor: props.data.slice(0, -1).map((item) => { return ("#" + Math.floor(Math.random() * 16777215).toString(16)) }),
                data: props.data.slice(0, -1)
            }
        ]
    }

    return (
        <div>
            <PolarArea
                data={state}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    )
}

export default PolarChart;
