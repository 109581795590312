import React from 'react';
// import styles from './Home.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setNav } from '../../store/utils';
import SubmitForm from '../../components/shared/SubmitForm/SubmitForm';
import ShowCompleteData from '../../components/shared/ShowCompleteData/ShowCompleteData';
import Topbar from '../../components/shared/Topbar/Topbar';

const Home = () => {
    const { nav, submitForm } = useSelector((state) => state.utils);
    const dispatch = useDispatch();

    function changeNav() {
        if (nav) {
            dispatch(setNav(false));
        } else {
            dispatch(setNav(true));
        }
    }


    return (
        <>
            <Topbar nav={nav} onClick={changeNav}>
                {/* SubmitForm or Show complete Date  */}
                {submitForm ? <ShowCompleteData /> : <SubmitForm />}
            </Topbar>
        </>
    )
}

export default Home;
