import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAnalyse, setQueryStr } from '../../../store/utils';
import DataList from '../DataList/DataList';
import Spinner from '../Spinner/Spinner';
import axios from 'axios';
import Cookies from 'js-cookie';

const ShowCompleteData = () => {
    const { displayField, selection, tableName, fromDate, toDate, queryStr } = useSelector((state) => state.utils);
    const [dataset, setDataset] = useState();
    const dispatch = useDispatch();

    function nextStep() {
        if (tableName !== "NewVHCPcrmdata")
            dispatch(setAnalyse(true));
    }
    let getTimeStamp = () => {
        let date = new Date();
        let time = date.getFullYear().toString() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        return time;
    }

    useEffect(() => {
        let jsonData = {
            "fromDate": fromDate.replace("T", " "),
            "toDate": toDate.replace("T", " "),
            "options": displayField,
            "menu": selection,
            "tableName": tableName,
        };

        async function fetchData() {
            try {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${Cookies.get("toc_crm")}`,
                    },
                });
                const { data } = await api.post("/api/sec/", jsonData);
                if (data.success) {
                    setDataset(data.data);
                    dispatch(setQueryStr(data.query));
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [dispatch, displayField, fromDate, selection, tableName, toDate]);

    return (
        <div>
            {dataset ? (<DataList btnParams={[{ 'btnValue': "Download", 'btnFun': false, 'filename': `VHCP_Extracted${getTimeStamp()}.csv`, 'queryStr': queryStr }, { 'btnValue': "Analyse", 'btnFun': nextStep }]} row={dataset.row} col={dataset.col} tableName={tableName} heading="CRM PORTAL" hasTotal={false} pagination={dataset.row.length} />
            ) : <Spinner />}
        </div>
    )
}

export default ShowCompleteData;