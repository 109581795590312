import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Navigation from './components/shared/Navigation/Navigation';
import { useSelector, useDispatch } from 'react-redux';
import Home from './pages/Home/Home';
import Analysis from './pages/Analysis/Analysis';
import SignIn from './pages/SignIn/SignIn';
import Cookies from 'js-cookie';
import { setAuth } from './store/authSlice';
import { useEffect } from 'react';


const App = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    const readCookie = () => {
      const token = Cookies.get("toc_crm");
      if (token) {
        dispatch(setAuth(true));
      }
    }
    readCookie();
  }, [dispatch]);

  return (
    <div className="container">
      <BrowserRouter>
        {isAuth ? <Navigation /> : ""}
        <Switch>
          <GuestRoute path="/login" exact>
            <SignIn />
          </GuestRoute>
          <SemiProtectedRoute path="/" exact>
            <Home />
          </SemiProtectedRoute>
          <ProtectedRoute path="/analyse" exact>
            <Analysis />
          </ProtectedRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const GuestRoute = ({ children, ...rest }) => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <Route {...rest}
      render={({ location }) => {
        return isAuth ? (
          <Redirect to={
            {
              pathname: "/",
              state: { from: location }
            }
          } />
        ) : (
          children
        );
      }}>
    </Route>
  );
}

const SemiProtectedRoute = ({ children, ...rest }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const { analyse } = useSelector((state) => state.utils);
  return (
    <Route {...rest}
      render={({ location }) => {
        return (
          !isAuth ? (
            <Redirect to={{
              pathname: "/login",
              state: { from: location }
            }} />
          ) : (
            isAuth && !analyse ?
              (children) : (
                <Redirect to={{
                  pathname: '/analyse',
                  state: { from: location }
                }} />
              )
          )
        )
      }}
    ></Route>
  )
}

const ProtectedRoute = ({ children, ...rest }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const { analyse } = useSelector((state) => state.utils);
  return (
    <Route {...rest}
      render={({ location }) => {
        return (
          !isAuth ? (
            <Redirect to={{
              pathname: "/login",
              state: { from: location }
            }} />
          ) : (
            isAuth && !analyse ?
              (
                <Redirect to={{
                  pathname: '/',
                  state: { from: location }
                }} />
              ) : (children)
          )
        )
      }}
    ></Route>
  )
}

export default App;
