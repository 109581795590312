import React from 'react';
import styles from './Cards.module.css';

const Cards = (props) => {
    const array = [{ "cardName": "Total Entries", "iconClass": "fas fa-address-book" }, { "cardName": "Agent Count", "iconClass": "fas fa-user-check" }];

    return (
        <div className={styles.cardBox}>
            {props.names !== undefined ? (array.map((item, pos) => (
                <div key={pos} className={styles.card}>
                    <div>
                        <div className={styles.numbers}>{props.names[Object.keys(props.names)[pos]]}</div>
                        <div className={styles.cardName}>{item.cardName}</div>
                    </div>
                    <div className={styles.iconBox}>
                        <i className={item.iconClass}></i>
                    </div>
                </div>
            ))) : ""}
        </div>
    )
}

export default Cards;
