import React from 'react';
import styles from './Topbar.module.css';

const Topbar = (props) => {
    return (
        <div className={[styles.main, props.nav ? styles.active : ""].join(" ")}>
            <div className={styles.topbar}>
                <div className={styles.toggle} onClick={props.onClick}>
                    <i className="fas fa-bars"></i>
                </div>
                {/* search  */}
                <div className={styles.search}>
                    <label>
                        <input type="text" placeholder="Search here" />
                        <i className="fas fa-search"></i>
                    </label>
                </div>
                {/* User Avatar  */}
                <div className={styles.user}>
                    <img src="/images/user.jpg" alt="User Avatar" />
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default Topbar;
