import React from 'react';
import styles from './Button.module.css';

const Button = (props) => {
    return (
        <button onClick={props.onClick} className={styles.button}>
            <span>{props.text}</span>
            <i className={["fas fa-arrow-right", styles.arrow].join(" ")}></i>
        </button>
    )
}

export default Button;
