import React from 'react';
import styles from './Spinner.module.css';

const Spinner = () => {
    return (
        <>
            <div className={styles.loader} ></div>
            <div className={styles.loaderText}>Loading...</div>
        </>
    )
}

export default Spinner;
