import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = (props) => {
    const state = {
        labels: props.labels.slice(0, -1),
        datasets: [
            {
                label: props.label,
                backgroundColor: 'rgba(255,99,132,0.5)',
                data: props.data.slice(0, -1),
                borderColor: "rgba(66, 135, 245, 0.8)",
            }
        ]
    }

    return (
        <div>
            <Line
                data={state}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                    }
                }}
            />
        </div>
    )
}

export default LineChart;
