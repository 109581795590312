import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './StepDatasetType.module.css';
import Button from '../../Button/Button';
import { setTableName } from '../../../../store/utils';

const StepDatasetType = (props) => {
    const { tableName } = useSelector((state) => state.utils);
    const dispatch = useDispatch();
    const [borderColor, setBorderColor] = useState("#95bbfa");
    const [warning, setWarning] = useState(false);

    function nextStep() {
        if (tableName)
            props.onNext();
        else {
            setBorderColor("red");
            setWarning(true);
        }
    }

    function handleClick(value) {
        dispatch(setTableName(value));
        setBorderColor("#95bbfa");
    }

    const array = [{ 'cardName': "Master Table(VHCP_DATA)", 'tableName': "NewVHCPcrmdata" }, { 'cardName': "Attempt Data(VHCP_STATUS)", 'tableName': "VHCPStatus" }];

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                {warning ? (
                    <p className={styles.warning}>
                        Please select any of the table name !!
                    </p>
                ) : ""}
                <div className={styles.cardWrapper}>
                    {array.map((item, pos) => (
                        <div key={pos} style={{ border: `2px solid ${borderColor}` }} className={styles.card} onClick={() => { handleClick(item.tableName); setWarning(false); }}>
                            {tableName === item.tableName ?
                                (<i className={["fas fa-check-circle", styles.icon].join(" ")}></i>)
                                : (<div className={styles.numbers}></div>)}
                            <div className={styles.cardName}>{item.cardName}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={nextStep} text="Next" />
                </div>
            </div>
        </div>
    )
}

export default StepDatasetType;
