import React, { useState } from 'react';
import styles from './Pagination.module.css';

const Pagination = (props) => {
    const [activePagination, setActivePagination] = useState(0);
    const [showAllRow, setShowAll] = useState(false);

    function prevPage(value) {
        if (activePagination !== 0)
            setActivePagination(value);
    }
    function currentPage(value) {
        setActivePagination(value);
    }
    function nextPage(value) {
        if (activePagination !== props.rowLength - 1)
            setActivePagination(value);
    }
    function showAllRowFun(value) {
        setShowAll(value);
    }

    return (
        <ul className={styles.ul}>
            <li><button onClick={() => { prevPage(activePagination - 1); props.prevPage(activePagination - 1) }}><i className="fas fa-less-than"></i></button></li>

            {[...Array(props.rowLength).keys()].map((item, pos) => (
                pos === 3 ? (
                    activePagination > 2 && activePagination < props.rowLength - 1 ? (
                        <li key={pos}>
                            <button onClick={() => { showAllRowFun(showAllRow ? false : true); props.showAll(showAllRow ? false : true) }} >{showAllRow ? "Show Less" : "Show All"}</button>
                            <button className={styles.active}>{activePagination + 1}</button>
                        </li>
                    ) : (<li key={pos}><button onClick={() => { showAllRowFun(showAllRow ? false : true); props.showAll(showAllRow ? false : true) }} >{showAllRow ? "Show Less" : "Show All"}</button></li>)
                )
                    : (pos > 3 && pos < props.rowLength - 1) ? "" : (
                        <li key={pos}><button className={activePagination === item ? styles.active : ""} onClick={() => { currentPage(item); props.currentPage(item) }}>{item + 1}</button></li>
                    )
            ))}

            <li><button onClick={() => { nextPage(activePagination + 1); props.nextPage(activePagination + 1) }}><i className="fas fa-greater-than"></i></button></li>
        </ul>
    )
}

export default Pagination
