import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Topbar from '../../components/shared/Topbar/Topbar';
import { setNav } from '../../store/utils';
import Cards from '../../components/shared/Cards/Cards';
import DataList from '../../components/shared/DataList/DataList';
import Chart from '../../components/shared/Charts/Chart';
import Spinner from '../../components/shared/Spinner/Spinner';
import axios from 'axios';
import Cookies from 'js-cookie';

const Analysis = () => {
    const { nav, displayField, selection, tableName, fromDate, toDate, queryStr } = useSelector((state) => state.utils);
    const [dataset, setDataset] = useState();
    const dispatch = useDispatch();

    function changeNav() {
        if (nav) {
            dispatch(setNav(false));
        } else {
            dispatch(setNav(true));
        }
    }
    let getTimeStamp = () => {
        let date = new Date();
        let time = date.getFullYear().toString() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        return time;
    }

    useEffect(() => {
        let jsonData = {
            "fromDate": fromDate.replace("T", " "),
            "toDate": toDate.replace("T", " "),
            "options": displayField,
            "menu": selection,
            "tableName": tableName,
            "mainQuery": queryStr,
        };

        async function fetchData() {
            try {
                const api = axios.create({
                    baseURL: process.env.REACT_APP_API_URL,
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${Cookies.get("toc_crm")}`,
                    },
                });
                const { data } = await api.post('/api/sec2/', jsonData);
                if (data.success)
                    setDataset(data.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [displayField, fromDate, queryStr, selection, tableName, toDate]);

    return (
        <>
            <Topbar nav={nav} onClick={changeNav}>
                {/* Cards  */}
                {dataset ? <Cards names={dataset.names} /> : ""}

                {dataset ? dataset.row.map((item, pos) => (
                    <div key={pos}>
                        {/* Charts  */}
                        {dataset && dataset.row[pos][0].length > 1 && pos !== dataset.row.length - 1 ? <Chart dataset={dataset.row[pos][0]} label={dataset.col[pos][dataset.col[pos].length - 1] === "Total" ? "Total" : dataset.col[pos][1]} hasTotal={dataset.col[pos][dataset.col[pos].length - 1] === "Total" ? true : false} /> : ""}
                        {/* Datasets  */}
                        {dataset ? (<DataList btnParams={[{ 'btnValue': "Download", 'directDownload': true, 'btnFun': false, 'filename': `AnalysisVHCP(${dataset.table[pos].replaceAll(" ", "")}${getTimeStamp()}).csv` }]} row={dataset.row[pos]} col={dataset.col[pos]} tableName={tableName} heading="CRM DATA ANALYSIS" subHeading={dataset.table[pos] ? dataset.table[pos] : null} hasTotal={pos !== dataset.row.length - 1 ? true : false} pagination={dataset.row[pos].length} />
                        ) : <Spinner />}
                    </div>
                )) : <Spinner />}
            </Topbar>
        </>
    )
}

export default Analysis
