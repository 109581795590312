import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './StepChooseDate.module.css';
import Button from '../../Button/Button';
import { setDate, setSubmitForm } from '../../../../store/utils';

const StepChooseDate = (props) => {
    const { fromDate, toDate } = useSelector((state) => state.utils);
    const [fromDateLocal, setFromDateLocal] = useState(fromDate ? fromDate : "2021-08-12T19:30");
    const [toDateLocal, setToDateLocal] = useState(toDate ? toDate : "2021-08-13T19:30");
    const dispatch = useDispatch();
    const [borderColor, setBorderColor] = useState("#95bbfa");
    const [warning, setWarning] = useState(false);

    function submit() {
        if (fromDateLocal && toDateLocal)
            if (fromDateLocal >= toDateLocal)
                setWarning(true);
            else {
                dispatch(setDate({ fromDate: fromDateLocal, toDate: toDateLocal }));
                dispatch(setSubmitForm(true));
            }
        else {
            setBorderColor("red");
            setTimeout(function () { alert("Please choose the date and time !!"); }, 600);
        }
    }

    function handleChange(value) {
        if (fromDateLocal && toDateLocal)
            if (fromDateLocal >= value) {
                setWarning(true);
                dispatch(setDate({ fromDate: null, toDate: null }));
            } else {
                setWarning(false);
                dispatch(setDate({ fromDate: fromDateLocal, toDate: toDateLocal }));
            }

    }

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                {warning ? (
                    <p className={styles.warning}>
                        "From" date should not be greater than or equal to "To" date
                    </p>
                ) : ""}
                <div className={styles.cardWrapper}>
                    <div style={fromDate ? { border: "2px solid #95bbfa" } : { border: `2px solid ${borderColor}` }} className={styles.card}>
                        <div className={styles.cardName}>From: </div>
                        <input className={styles.dateInput} type="datetime-local" min="2021-07-06T00:00" max="2021-09-10T00:00" onChange={(e) => setFromDateLocal(e.target.value)} value={fromDateLocal} onClick={() => { setBorderColor("#95bbfa"); setWarning(false); }}></input>
                    </div>
                    <div style={toDate ? { border: "2px solid #95bbfa" } : { border: `2px solid ${borderColor}` }} className={styles.card}>
                        <div className={styles.cardName}>To: </div>
                        <input className={styles.dateInput} type="datetime-local" min="2021-07-06T00:00" max="2021-09-10T00:00" onChange={(e) => { setToDateLocal(e.target.value); handleChange(e.target.value); }} value={toDateLocal} onClick={() => { setBorderColor("#95bbfa"); setWarning(false); }}></input>
                    </div>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={submit} text="Submit" />
                </div>
            </div>
        </div>
    )
}

export default StepChooseDate;
