import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nav: false,
    currentPage: "Home",
    tableName: null,
    displayField: null,
    selection: null,
    fromDate: null,
    toDate: null,
    submitForm: false,
    analyse: false,
    queryStr: null,
};

export const utilsState = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        setNav: (state, action) => {
            state.nav = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTableName: (state, action) => {
            state.tableName = action.payload;
        },
        setFieldSelection: (state, action) => {
            const { displayField, selection } = action.payload;
            state.displayField = displayField;
            state.selection = selection;
        },
        setDate: (state, action) => {
            const { fromDate, toDate } = action.payload;
            state.fromDate = fromDate;
            state.toDate = toDate;
        },
        setSubmitForm: (state, action) => {
            state.submitForm = action.payload;
        },
        setAnalyse: (state, action) => {
            state.analyse = action.payload;
        },
        setQueryStr: (state, action) => {
            state.queryStr = action.payload;
        }
    },
})

export const { setNav, setCurrentPage, setTableName, setFieldSelection, setDate, setSubmitForm, setAnalyse, setQueryStr } = utilsState.actions;

export default utilsState.reducer;