import React, { useState } from 'react';
import styles from './Navigation.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../store/utils';
import { setAuth } from '../../../store/authSlice';
import Cookies from 'js-cookie';

const Navigation = () => {
    const [active, setActive] = useState("Home");
    const { nav } = useSelector((state) => state.utils);
    const dispatch = useDispatch();

    const singOut = () => {
        dispatch(setAuth(false));
        try {
            Cookies.remove("toc_crm");
        } catch (error) {
            console.log("Cookie expired");
        }
    }

    const options = [{ 'page': "Home", 'link': null, 'iconClass': "fas fa-home" }, { 'page': "Contact", 'link': "https://blueplanetsolutions.com/contact-us/", 'iconClass': "fas fa-phone-alt" }, { 'page': "About Us", 'link': "https://blueplanetsolutions.com/about-us/", 'iconClass': "fas fa-question" }, { 'page': "Password", 'link': null, 'iconClass': "fas fa-lock" }, { 'page': "Sign Out", 'link': null, 'iconClass': "fas fa-sign-out-alt", 'fun': true }];

    function handleClick(value) {
        setActive(value);
        dispatch(setCurrentPage(value));
    }

    return (
        <div className={[styles.navigation, nav ? styles.active : ""].join(" ")}>
            <ul>
                <li>
                    <a href="https://blueplanetsolutions.com/">
                        <span className={[styles.icon].join(" ")}>
                            <img className={[styles.brand].join(" ")} src="/images/brand.png" alt="Brand logo" />
                        </span>
                        <span className={[styles.title, styles.brandTitleWrapper].join(" ")}>
                            <p>BLUE PLANET</p>
                            <p>SOLUTIONS INC.</p>
                        </span>
                    </a>
                </li>
                {options.map((item, pos) => (
                    <li key={pos} className={[active === item.page ? styles.hovered : ""].join(" ")} onClick={() => handleClick(item.page)}>
                        <a href={item.link} onClick={item.fun ? singOut : () => {}}>
                            <span className={[styles.icon].join(" ")}><i className={item.iconClass}></i></span>
                            <span className={[styles.title].join(" ")}>{item.page}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Navigation;
